<template>
  <head>
    <title>Garage Connect - Accueil</title>
    <meta charset="utf-8">
  </head>
  <div class="container-fluid px-5 py-4">

    <!-- Bouton pour ouvrir la modale -->
    <button class="btn btn-success btn-lg rounded-circle" @click="() => {showModal = true;}"
            style="width: 75px; height: 75px;">
      <strong style="font-size: 1.5rem;">+</strong>
    </button>

    <!-- Modale de confirmation -->
    <div v-if="showConfirmationModal" class="modal fade show" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-verify" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirmer le changement de statut</h5>
            <button type="button" class="btn-close" @click="closeConfirmationModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Voulez-vous vraiment marquer ce client comme "Terminé" ?</p>
            <p>Cette action enverra un e-mail ou un sms au client.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeConfirmationModal">Non</button>
            <button type="button" class="btn btn-success" @click="confirmStatusChange">Oui</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modale pour ajouter un client -->
    <div v-if="showModal" class="modal fade show" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-customer" role="document">
        <div class="modal-content">
          <div class="modal-header p-4">
            <h5 class="modal-title">Client</h5>
            <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-4">
            <form @submit.prevent="saveCustomer">
              <!-- Champs obligatoires -->
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="plate" class="form-label">
                    Plaque d'immatriculation <span class="text-danger">*</span>
                  </label>
                  <input v-model="newCustomer.plate" id="plate" type="text" class="form-control" required/>
                </div>
                <div class="col-md-6">
                  <label for="contactType" class="form-label">
                    Type de contact <span class="text-danger">*</span>
                  </label>
                  <select v-model="newCustomer.contactType" id="contactType" class="form-select" required>
                    <option value="1">Email</option>
                    <option value="2">Téléphone</option>
                  </select>
                </div>
              </div>

              <!-- Affichage conditionnel de l'email ou du téléphone -->
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="email" class="form-label">
                    Email <span v-if="newCustomer.contactType === '1'" class="text-danger">*</span>
                  </label>
                  <input v-model="newCustomer.email" id="email" type="email" class="form-control"
                         :required="newCustomer.contactType === '1'"/>
                </div>
                <div class="col-md-6">
                  <label for="phone" class="form-label">
                    Téléphone <span v-if="newCustomer.contactType === '2'" class="text-danger">*</span>
                  </label>
                  <input v-model="newCustomer.phone" id="phone" type="tel" class="form-control"
                         :required="newCustomer.contactType === '2'"/>
                </div>
              </div>

              <!-- Champs facultatifs supplémentaires -->
              <div class="row g-3 mt-3">
                <div class="col-md-6">
                  <label for="firstname" class="form-label">Prénom (facultatif)</label>
                  <input v-model="newCustomer.firstname" id="firstname" type="text" class="form-control"/>
                </div>
                <div class="col-md-6">
                  <label for="lastname" class="form-label">Nom (facultatif)</label>
                  <input v-model="newCustomer.lastname" id="lastname" type="text" class="form-control"/>
                </div>
              </div>

              <div class="mt-3">
                <label for="description" class="form-label">Description (facultatif)</label>
                <textarea v-model="newCustomer.description" id="description" class="form-control" rows="3"></textarea>
              </div>

              <!-- Note indiquant les champs obligatoires -->
              <div class="mt-2">
                <small><span class="text-danger">*</span> Champs obligatoires</small>
              </div>

              <div class="modal-footer p-4 mt-3 border-0 justify-content-center">
                <button type="button" class="btn btn-danger btn-lg" @click="closeModal">Annuler</button>
                <!-- Bouton de soumission (ajouter ou mettre à jour) -->
                <button type="submit" class="btn btn-success btn-lg"
                        :disabled="emailError || phoneError">
                  Valider
                </button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>

    <!-- Affichage des clients en colonnes selon le statut -->
    <div v-if="!isLoading" class="row mt-4 p-0">
      <div v-for="(label, status) in statusLabels" :key="status" class="col-md-4 drop-zone p-0"
           @dragover.prevent="handleDragOver"
           @dragleave="handleDragLeave"
           @drop="handleDrop($event, status)">
        <div class="card p-2 border-0 text-center">
          <div class="card-header text-white"
               :class="{'bg-secondary': label !== 'Terminé', 'bg-success': label === 'Terminé'}">
            <h5 class="mb-0">{{ label }}</h5>
          </div>
          <ul class="list-group list-group-flush text-center">

            <li v-for="customer in groupedCustomers[status]" :key="customer.id"
                draggable="true"
                @dragstart="handleDragStart(customer)"
                @touchstart="handleTouchStart(customer)"
                @touchmove="handleTouchMove"
                @touchend="handleTouchEnd(status)"
                class="list-group-item d-flex justify-content-start align-items-center w-100">

              <div class="d-flex align-items-center w-100 px-4 my-3">

                <!-- Affichage des informations du client aligné au centre -->
                <div class="text-start flex-grow-1">
                  <!-- Plaque d'immatriculation -->
                  <strong class="plate d-block">{{ customer.plate || 'Aucune plaque' }}</strong>

                  <!-- Téléphone ou email -->
                  <div v-if="customer.phone || customer.email">
                    <small>{{customer.contactType === 1 ? customer.email : customer.phone }}</small>
                  </div>
                </div>

                <!-- Colonne des actions alignées verticalement à gauche -->
                <div class="actions d-flex flex-row align-items-center justify-content-between gap-3">
                  <!-- Bouton pour mettre à jour le client -->
                  <button type="button" class="btn btn-primary" @click="openUpdateModal(customer)">
                    <i class="fas fa-edit"></i>
                  </button>

                  <!-- Bouton pour supprimer le client -->
                  <button type="button" class="btn btn-danger" @click="deleteCustomer(customer.id)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>

              </div>
            </li>


          </ul>
        </div>
      </div>
    </div>

  </div>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
</template>

<script setup>
import {ref, onMounted, computed} from 'vue';
import {CustomerService} from '@/services/apiService';
import {AuthService} from '@/services/authService';
import {useRouter} from 'vue-router';

// État pour les clients, le chargement et les erreurs
const customers = ref([]);
const isLoading = ref(true);
const error = ref(null);
const draggedCustomer = ref(null); // Stocke le client en cours de drag

const showConfirmationModal = ref(false); // Contrôle l'affichage de la modale de confirmation
const customerToUpdate = ref(null); // Stocke le client à mettre à jour
const newStatusToUpdate = ref(null); // Stocke le nouveau statut à appliquer

const showModal = ref(false); // Contrôle l'affichage de la modale

const isTest = ref(false);

const newCustomer = ref({
  id: null,
  plate: '',
  email: '',
  phone: '',
  status: '1',
  contactType: '',
  firstname: '',
  lastname: '',
  description: ''
});

const router = useRouter();

const emailError = ref(null);
const phoneError = ref(null);

// Dictionnaire des labels de statut
const statusLabels = {
  1: "Pris en compte",
  2: "En attente de pièces",
  3: "Terminé",
};

// Fonction pour ouvrir la modale d'édition
const openUpdateModal = (customer) => {
  newCustomer.value = {...customer}; // Remplir le formulaire avec les données du client sélectionné
  showModal.value = true; // Ouvrir la modale
};

// Fonction pour ajouter un client
const addCustomer = async () => {
  try {
    const response = await CustomerService.createCustomer(newCustomer.value);
    closeModal(); // Fermer la modale après l'ajout
    newCustomer.value = {
      id: '',
      plate: '',
      email: '',
      phone: '',
      status: '1',
      contactType: '',
      firstname: '',
      lastname: '',
      description: ''
    };
  } catch (err) {
    error.value = 'Erreur lors de l\'ajout du client.';
  }
  await fetchCustomers()
};

// Fonction pour ajouter un client
const updateCustomer = async (id) => {
  try {
    const response = await CustomerService.updateCustomer(id, newCustomer.value);
    closeModal(); // Fermer la modale après l'ajout
    newCustomer.value = {
      id: '',
      plate: '',
      email: '',
      phone: '',
      status: '1',
      contactType: '',
      firstname: '',
      lastname: '',
      description: ''
    };
  } catch (err) {
    error.value = 'Erreur lors de l\'edition du client.';
  }
  await fetchCustomers()
};

const saveCustomer = async () => {
  if (newCustomer.value.id) {
    // Si l'ID existe, on fait une mise à jour
    await updateCustomer(newCustomer.value.id);
  } else {
    // Sinon, on ajoute un nouveau client
    await addCustomer();
  }
};

// Fonction pour fermer la modale
const closeModal = () => {
  showModal.value = false;
};

const handleDragOver = (evt) => {
  console.log('Drag over:', evt);
  evt.currentTarget.classList.add('dragover');
};

const handleDragLeave = (evt) => {
  console.log('Drag leave:', evt);
  evt.currentTarget.classList.remove('dragover');
};

// Méthode pour récupérer tous les clients
const fetchCustomers = async () => {
  try {
    const response = await CustomerService.getAllCustomers();
    console.log('Clients récupérés:', response.data);
    customers.value = response.data;
  } catch (err) {
    console.error('Erreur de chargement:', err);
  } finally {
    isLoading.value = false;
  }
};

// Fonction pour supprimer un client
const deleteCustomer = async (customerId) => {
  try {
    // Appel à l'API pour supprimer le client
    await CustomerService.deleteCustomer(customerId);
    console.log('Customer deleted:', customerId);
    // Supprimer le client localement
    await fetchCustomers();
  } catch (err) {
    console.error('Erreur lors de la suppression:', err);
  }
};

// Regrouper les clients par statusLabel
const groupedCustomers = computed(() => {
  const grouped = {};
  customers.value.forEach(customer => {
    const status = customer.status; // Assumer que 'status' existe
    if (!grouped[status]) {
      grouped[status] = [];
    }
    grouped[status].push(customer);
  });
  return grouped;
});

// Fonction appelée lorsque le drag commence
const handleDragStart = (customer) => {
  draggedCustomer.value = customer;
  console.log('Dragged customer:', customer);
};

// Fonction pour gérer le drop avec confirmation
const handleDrop = async (evt, newStatus) => {
  evt.preventDefault();
  console.log('Dropped customer:', draggedCustomer.value);
  console.log('New status:', newStatus);

  evt.currentTarget.classList.remove('dragover');

  if (draggedCustomer.value && draggedCustomer.value.status !== parseInt(newStatus)) {
    if (newStatus === '3' && draggedCustomer.value.status != 3) {
      console.log('Confirmation required')
      customerToUpdate.value = draggedCustomer.value;
      newStatusToUpdate.value = newStatus;
      showConfirmationModal.value = true;
    } else {
      await updateCustomerStatus(draggedCustomer.value, newStatus);
    }
  }
};

const handleTouchStart = (customer) => {
  draggedCustomer.value = customer;
  console.log('Touch start customer:', customer);
};

const handleTouchMove = (evt) => {
  // Gérer le mouvement du doigt sur l'écran si nécessaire
  console.log('Touch move');
};

const handleTouchEnd = (newStatus) => {
  if (draggedCustomer.value && draggedCustomer.value.status !== parseInt(newStatus)) {
    updateCustomerStatus(draggedCustomer.value, newStatus);
  }
};

// Fonction pour confirmer le changement de statut
const confirmStatusChange = async () => {
  if (customerToUpdate.value) {
    await updateCustomerStatus(customerToUpdate.value, 3);
  }
  closeConfirmationModal();
};

// Fonction pour mettre à jour le statut du client
const updateCustomerStatus = async (customer, newStatus) => {
  try {
    const updatedCustomer = {...customer, status: newStatus};
    await CustomerService.updateCustomer(customer.id, updatedCustomer);
    customers.value = customers.value.map(c => c.id === customer.id ? updatedCustomer : c);
    draggedCustomer.value = null;
  } catch (err) {
    error.value = 'Erreur lors de la mise à jour du statut.';
    console.error(err);
  }
};

// Fonction pour fermer la modale de confirmation
const closeConfirmationModal = () => {
  showConfirmationModal.value = false;
  customerToUpdate.value = null;
  newStatusToUpdate.value = null;
};

// Récupérer les clients au montage du composant
onMounted(async () => {
  const user = await AuthService.me();
  if (!user) {
    await router.push({name: 'login'});
  }

  if (user.isTest) {
    isTest.value = true;
  }

  if (user.roles && user.roles.includes('ROLE_ADMIN')) {
    await router.push({name: 'admin'});
  }
  await fetchCustomers();
});
</script>

<style scoped>
.modal-dialog-customer {
  min-width: 40vw;
}

.modal-dialog-verify {
  min-width: 25vw;
}

h2 {
  font-size: 1.5rem;
  color: #2c3e50;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.0rem;
  margin-top: 5px;
  border-radius: 10px;
  cursor: grab;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
  padding: 15px 50px;
  background-color: #f1f1f1;
}

li:active {
  cursor: grabbing;
}

li:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
  background-color: #eaeaea;
}

.modal {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.25);
  width: 100vw;
}

.drop-zone {
  min-height: calc(65vh + 100px);
  border-radius: 10px;
  border: 5px solid white;
  background-color: #f9f9f9;
}

.drop-zone.dragover {
  background-color: #eaeaea;
}

.plate {
  font-size: 1.15rem;
}
</style>
